import React from "react";
import { Link } from "gatsby";
import ServicePage from "../../components/ServicePage";
import PageMeta from "../../components/PageMeta";

export default function HouseworkPage() {
  return (
    <ServicePage>
      <PageMeta
        title="QCL Services - Overnight care"
        path="services/overnight-care"
      />
      <h1 className="top">Overnight Care</h1>
      <p className="lead">
        QCL’s principal activity is the provision of personal care at delegated
        times throughout the day.
      </p>
      <p>
        We are in a position to provide overnight care but this service needs to
        be booked in advance and will always be subject to staffing levels.
      </p>
      <p>
        If a Service User requires overnight care, then QCL will provide a
        separate quotation that will be developed according to the particular
        needs.
      </p>
      <p>
        For further information, please{" "}
        <Link to="/contact-us/">contact our office</Link>.
      </p>
    </ServicePage>
  );
}
